import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Messenger } from "../components/Messenger"

const Section = styled.section`
  margin: 1rem 0;
  .email {
    list-style: none;
  }
`
const Calendar = styled.div`
  text-align: center;
`
const ContactPage = ({ data, location }) => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  const siteTitle = data.site.siteMetadata.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Schedule a Meeting" />
      <Section>
        <Calendar>
          <h3>Schedule a Meeting</h3>
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/jomisayao/30min?background_color=4d5055&text_color=ffffff&primary_color=efc11a"
            style={{ minWidth: "320px", height: "750px" }}
          ></div>
        </Calendar>
      </Section>
      <Messenger />
    </Layout>
  )
}

export default ContactPage
export const pageQuery = graphql`
  query Contact {
    site {
      siteMetadata {
        title
      }
    }
  }
`
